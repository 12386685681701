import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GAME_STATE_CHEATED, GAME_STATE_FINISHED, GAME_STATE_NEW, GAME_STATE_RUNNING } from "../store/gameStateReducer";

export const useIsGameNew = () => {
  const gameState = useSelector((state) => state.gameState);

  const [state, setState] = useState(gameState.value === GAME_STATE_NEW);

  useEffect(() => {
    setState(gameState.value === GAME_STATE_NEW);
  }, [gameState]);

  return state;
}

export const useIsGameRunning = () => {
  const gameState = useSelector((state) => state.gameState);

  const [state, setState] = useState(gameState.value === GAME_STATE_RUNNING);

  useEffect(() => {
    setState(gameState.value === GAME_STATE_RUNNING);
  }, [gameState]);

  return state;
}

export const useIsGameFinished = () => {
  const gameState = useSelector((state) => state.gameState);

  const [state, setState] = useState(gameState.value === GAME_STATE_FINISHED);

  useEffect(() => {
    setState(gameState.value === GAME_STATE_FINISHED);
  }, [gameState]);

  return state;
}

export const useIsCheated = () => {
  const gameState = useSelector((state) => state.gameState);

  const [state, setState] = useState(gameState.value === GAME_STATE_CHEATED);

  useEffect(() => {
    setState(gameState.value === GAME_STATE_CHEATED);
  }, [gameState]);

  return state;
}