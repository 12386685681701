import { createAction, createReducer } from "@reduxjs/toolkit";

export const incrementError = createAction('gamestats/incrementError');
export const incrementSuccess = createAction('gamestats/incrementSuccess');
export const incrementKeyDown = createAction('gamestats/incrementKeyDown');
export const resetGameStats = createAction('gamestats/reset');

const initialState = { value: {
    errors:0, 
    successes:0,
    keyDownCount:0
} }

const gameStatsReducer = createReducer(initialState, (builder) => { 
    builder
    .addCase(incrementError, (state, action) => {
      state.value.errors++ ;
    })
    .addCase(incrementSuccess, (state, action) => {
        state.value.successes++ ;
    })
    .addCase(incrementKeyDown, (state, action) => {
        state.value.keyDownCount++ ;
    })
    .addCase(resetGameStats, (state, action) => {
        state.value.errors = 0 ;
        state.value.successes = 0 ;
        state.value.keyDownCount = 0 ;
    })
});

export default gameStatsReducer;

