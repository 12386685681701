import './App.css';
import { Provider } from 'react-redux';
import { gameStore } from './store/gameStore';
import { WrapperView } from './view/wrapperView';

function App() {
  return (
    <Provider store={gameStore}>
      <div className="App">
        <WrapperView />
        <section className="grid-container">
          <div className="grid-line"></div>
          <div className="grid-line"></div>
          <div className="grid-line"></div>
          <div className="grid-line"></div>
          <div className="grid-line"></div>
          <div className="grid-line"></div>
          <div className="grid-line"></div>
          <div className="grid-line"></div>
          <div className="grid-line"></div>
          <div className="grid-line"></div>
          <div className="grid-line"></div>
        </section>
      </div>

    </Provider>
  );
}

export default App;
