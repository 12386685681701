import { useDispatch } from 'react-redux';
import { startGame } from '../store/gameStateReducer';
import { useIsGameNew } from '../hooks/gameStateHooks';
import { useCallback } from 'react';
import { Leaderboard } from '../components/leaderboard';

export const MainView = (props) => {

    const isGameNew = useIsGameNew();

    const dispatch = useDispatch();

    const startNewGame = useCallback(() => {
        dispatch(startGame());
    }, [dispatch]);

    return (
        <div>
            {isGameNew && (
                <div
                    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                >
                    <Leaderboard
                        statsUrl={props.statsUrl}
                    />
                    <span className='game-sub-header'>
                        Are you the fastest?
                    </span>

                    {/* <span>INSERT COIN</span> */}
                    <span
                        className='button start-game-button'
                        onClick={startNewGame}
                    >
                        START GAME
                    </span>
                </div>
            )}
        </div>
    );
}
