import { codeSnippets } from "./codeSnippets";

export class CodeSnippetsRetriever {

    #alreadyReturnedIndexes = [];

    #getUnknownIndex() {
        if (this.#alreadyReturnedIndexes.length === codeSnippets.length) {
            return -1;
        }

        while(true) {
            const foundIndex = Math.floor(Math.random() * codeSnippets.length);
            if (!this.#alreadyReturnedIndexes.includes(foundIndex)) { 
                return foundIndex;
            }
        }
    }

    getRandom() {
         const randomIndex = this.#getUnknownIndex();
          if (randomIndex === -1) {
            return 'NO MORE SNIPPETS';
          }

          this.#alreadyReturnedIndexes.push(randomIndex);

          return codeSnippets[randomIndex];
    }

    reset() {
        this.#alreadyReturnedIndexes = [];
    }

}
