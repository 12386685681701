import { Input } from '../components/codeInput';
import { Display } from '../components/codeDisplay';
import { Timer } from '../components/timer';
import { useDispatch } from 'react-redux';
import { finishGame, setCheatDetected } from '../store/gameStateReducer';
import { useCallback, useEffect, useState } from 'react';
import { incrementError, incrementKeyDown, incrementSuccess } from '../store/gameStatsReducer';
import { useIsGameRunning } from '../hooks/gameStateHooks';

export const GameView = (props) => {

    const {codeSnippetsRetriever} = props;

    const [currentSnippet, setCurrentSnippet] = useState('');

    const isGameRunning = useIsGameRunning();

    const dispatch = useDispatch();

    const getNextCodeSnippet = useCallback(() => {
        const snippet = codeSnippetsRetriever.getRandom();
        setCurrentSnippet(snippet);
    }, [codeSnippetsRetriever]);

    const startNewGame = useCallback(() => {
        codeSnippetsRetriever.reset();
        getNextCodeSnippet();
    }, [codeSnippetsRetriever, getNextCodeSnippet]);

    const handleSuccess = () => {
        dispatch(incrementSuccess());
        getNextCodeSnippet();
    };

    const handleCheated = () => {
        dispatch(setCheatDetected());
    };

    const handleError = () => {
        dispatch(incrementError());
    };

    const handleTimerEnd = () => {
        dispatch(finishGame());
    };

    const handleKeyDown = () => {
        dispatch(incrementKeyDown());
    };

    useEffect(() => {
        if (!isGameRunning) {
            return;
        }
        startNewGame();
    }, [isGameRunning, startNewGame]);
 

    return (
        <>
            {isGameRunning && (
                <div>
                    <div style={{marginBottom: '24px'}}>
                    <span className='hint'>Type the same text as quickly as you can.</span>
                    </div>
                    <Timer
                        seconds={props.gameRuntimeSeconds}
                        timerZero={handleTimerEnd}
                    />

                    <Display
                      style={{marginTop: '20px'}}
                        text={currentSnippet}
                    />

                    <Input
                        style={{marginTop: '20px'}}
                        compareString={currentSnippet}
                        cheated={handleCheated}
                        success={handleSuccess}
                        onBackSpacePressed={handleError}
                        onKeyDown={handleKeyDown}
                    />
                </div>
            )}
        </>
    );
}
