import { useCallback, useEffect, useState } from "react";

export const Leaderboard = (props) => {

    const url = `${props.statsUrl}?stats=leaderboard`;

    const [leaderboard, setLeaderboard] = useState([]);

    const fetchData = useCallback(async () => {
        try {
            const result = await fetch(url);
            const data = await result.text();

            if (data && data !== '') {
                const parsed = JSON.parse(data);
                setLeaderboard(parsed);
            }
        } catch (error) {
            console.log('ERROR fetching leaderboard data: ', error);
        }
    }, [url]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div>
            {leaderboard && (
                <div className="leaderboard">
                    <h2>Leaderboard</h2>
                    <span className="leaderboard-header leaderboard-number-header">RANK</span>
                    <span className="leaderboard-header leaderboard-number-header">SUCCESSES</span>
                    <span className="leaderboard-header leaderboard-number-header">ERRORS</span>
                    <span className="leaderboard-header leaderboard-number-header">SCORE</span>
                    <span className="leaderboard-header leaderboard-text-header">USER</span>
                    {leaderboard.map((item, index) => (
                        <div
                            key={`leaderboard-item-${index + 1}`}
                            className="leaderboard-row"
                        >
                            <span className="leaderboard-item leaderboard-number-item">{index + 1}</span>
                            <span className="leaderboard-item leaderboard-number-item">{item.successes}</span>
                            <span className="leaderboard-item leaderboard-number-item">{item.errors}</span>
                            <span className="leaderboard-item leaderboard-number-item">{item.score}</span>
                            <span className="leaderboard-item leaderboard-text-item">{item.username}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );

}