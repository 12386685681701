import { useEffect, useState } from "react";

export const Timer = (props) => {


    const {timerZero} = props;

    const [time, setTime] = useState(props.seconds);

    useEffect(() => {
        let interval = null;

        interval = setInterval(() => {
            setTime(prevTime => prevTime - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (time === 0) {
            timerZero();
        }
    }, [time, timerZero]);

    return (
        <div>
            <p>Time: {time} seconds</p>
        </div>
    );

}