import { useDispatch } from "react-redux";
import { useIsCheated } from "../hooks/gameStateHooks";
import { setNewGame } from "../store/gameStateReducer";

export const CheatedView = () => {

    const dispatch = useDispatch();
    const isCheated = useIsCheated();

    const restartGame = () => {
        dispatch(setNewGame());
    };

    return (
        <>
            {isCheated && (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <span style={{ fontSize: 'xx-large', marginBottom: '48px'}}>CHEATER!</span>
                    <span
                        className="button"
                        onClick={restartGame}
                    >
                        Restart
                    </span>
                </div>
            )}
        </>
    );

}