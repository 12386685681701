import { createAction, createReducer } from "@reduxjs/toolkit";

export const GAME_STATE_NEW = 'NEW;'
export const GAME_STATE_RUNNING = 'RUNNING;'
export const GAME_STATE_FINISHED = 'FINISHED;'
export const GAME_STATE_CHEATED = 'CHEATED;'

export const setNewGame = createAction('gamestate/new')
export const startGame = createAction('gamestate/running')
export const finishGame = createAction('gamestate/finished')
export const setCheatDetected = createAction('gamestate/cheated')

const initialState = { value: GAME_STATE_NEW }

const gameStateReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setNewGame, (state, action) => {
      state.value = GAME_STATE_NEW;
    })
    .addCase(startGame, (state, action) => {
      state.value = GAME_STATE_RUNNING;
    })
    .addCase(finishGame, (state, action) => {
      state.value = GAME_STATE_FINISHED;
    })
    .addCase(setCheatDetected, (state, action) => {
      state.value = GAME_STATE_CHEATED;
    })
});

export default gameStateReducer;

