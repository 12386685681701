import { configureStore } from "@reduxjs/toolkit";
import gameStateReducer from "./gameStateReducer";
import gameStatsReducer from "./gameStatsReducer";

export const gameStore = configureStore({
    reducer: {
        gameState: gameStateReducer,
        gameStats: gameStatsReducer,
    }
});

export default gameStore;