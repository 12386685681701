import { useDispatch, useSelector } from "react-redux";
import { useIsGameFinished } from "../hooks/gameStateHooks";
import { setNewGame } from "../store/gameStateReducer";
import { useCallback, useEffect, useRef, useState } from "react";
import { resetGameStats } from "../store/gameStatsReducer";

export const ResultView = (props) => {

    const url = `${props.statsUrl}?stats=leaderboard`;

    const usernameInputRef = useRef(null);

    const [isOnLeaderboard, setIsOnLeaderboard] = useState(false);
    const [zeroSuccesses, setZeroSuccesses] = useState(true);
    const [leaderboard, setLeaderboard] = useState([]);

    const gameStats = useSelector((state) => state.gameStats);

    const dispatch = useDispatch();
    const isGameFinished = useIsGameFinished();

    const apm = Math.ceil(gameStats.value.keyDownCount / (props.gameRuntimeSeconds / 60));

    const calcScore = (successes, errors, apm) => {
        return (parseInt(successes) * 100) + parseInt(apm) - parseInt(errors);
    }

    const getUpdatedLeaderboard = () => {
        const username = usernameInputRef.current.value;
        const successes = gameStats.value.successes;
        const errors = gameStats.value.errors;

        if (successes === 0) {
            return leaderboard;
        }

        const score = calcScore(successes, errors, apm);

        if (leaderboard.length === 0) {
            return [{ username, successes, errors, apm, score }];
        }

        let indexToInsert = -1;
        for (let i = 0; i < leaderboard.length; i++) {
            const element = leaderboard[i];
            
            if (score >= element.score) {
                indexToInsert = i;
                break;
            }
        }

        if (indexToInsert === -1 && leaderboard.length <= props.maxTopUsers) {
            indexToInsert = leaderboard.length;
        }

        if (indexToInsert !== -1) {

            let tmpleaderboard = [...leaderboard];
            tmpleaderboard.splice(indexToInsert, 0, { username, successes, errors, apm, score });
            tmpleaderboard = tmpleaderboard.slice(0, props.maxTopUsers);
            return tmpleaderboard;
        }

        return leaderboard;
    };

    const saveLeaderboard = async () => {
        try {

            const newLeaderboard = getUpdatedLeaderboard();

            const data = new FormData();
            data.append('leaderboard', JSON.stringify(newLeaderboard));

            const res = await fetch(props.statsUrl, {
                method: 'POST',
                body: data,
            });

            if (res.status !== 200) {
                console.log('ERROR: could not save leaderboard');
                console.log('res: ', res);
            }
        } catch (error) {
            console.log('ERROR saving leaderboard: ', error);
        }
    };

    const checkIfBetter = useCallback(() => {
        const successes = gameStats.value.successes;
        const errors = gameStats.value.errors;
        const newScore = calcScore(successes, errors, apm);

        if (successes === 0) {
            setZeroSuccesses(true);
            setIsOnLeaderboard(false);
            return;
        } else {
            setZeroSuccesses(false);
        }

        if (leaderboard.length === 0) {
            setIsOnLeaderboard(true);
            return;
        }

        for (let i = 0; i < leaderboard.length; i++) {
            const element = leaderboard[i];
            const currentScore = calcScore(element.successes, element.errors, element.apm);

            if (newScore >= currentScore) {
                setIsOnLeaderboard(true);
                break;
            }
        }

        setIsOnLeaderboard(false);
    }, [gameStats, leaderboard, apm]);

    const fetchData = async () => {
        try {
            const result = await fetch(url);
            const data = await result.text();

            if (data && data !== '') {
                const parsed = JSON.parse(data);
                setLeaderboard(parsed);
            }

            checkIfBetter();
        } catch (error) {
            console.log('ERROR fetchting leaderboard: ', error);
        }
    };

    useEffect(() => {
        fetchData();
        // fetchData
        // eslint-disable-next-line
    }, []);

    const restartGame = async () => {
        dispatch(resetGameStats());
        dispatch(setNewGame());
    };

    const onRestartClicked = async () => {
        if (isOnLeaderboard) {
            await saveLeaderboard();
        }
        await restartGame();
    }

    return (
        <>
            {isGameFinished && (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '32px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '4px' }}>
                        {zeroSuccesses && (
                            <span className="hint">You need at least one correct input!</span>
                        )}
                        <span>Your results:</span>
                        <div>
                            <span className="result-header">Correct</span>
                            <span className="result-value">{gameStats.value.successes}</span>
                        </div>
                        <div>
                            <span className="result-header">Errors</span>
                            <span className="result-value">{gameStats.value.errors}</span>
                        </div>
                        <div>
                            <span className="result-header">APM</span>
                            <span className="result-value">{apm}</span>
                        </div>
                    </div>

                    {isOnLeaderboard && (
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '4px' }}>
                            <span>Enter your name:</span>
                            <input
                                ref={usernameInputRef}
                                className="name-input"
                                maxLength={15}
                                autoFocus={true}
                                type="text"
                            />
                        </div>
                    )}

                    <span
                        className="button"
                        onClick={onRestartClicked}
                    >
                        Restart
                    </span>
                </div >
            )}
        </>
    );

}