
export const Display = (props) => {

    return (
        <textarea
            className="text-area"
            type="text"
            value={props.text}
            disabled={true} />
    )
}