const s1 = `const numbers = [1, 2, 3, 4, 5];
const doubledNumbers = numbers.map(num => num * 2);

console.log("Original numbers:", numbers);
console.log("Doubled numbers:", doubledNumbers);`

const s2 = `const isPalindrome = str => {
  const reversed = str.split("").reverse().join("");
  return str === reversed;
};

console.log(isPalindrome("radar"));
console.log(isPalindrome("hello"));`

const s3 = `const reverseString = str => {
  let reversed = '';
  for (let i = str.length - 1; i >= 0; i--) {
    reversed += str[i];
  }
  return reversed;
};

console.log(reverseString("hello"));`

const s4 = `function swap(a, b) {
  a = a + b;
  b = a - b;
  a = a - b;
  return [a, b];
}

const swap=(a,b)=>[a+b,a=a-b,b=a-b];`

const s5 = `const findMax = arr => {
  let max = arr[0];
  for (let i = 1; i < arr.length; i++) {
    if (arr[i] > max) {
      max = arr[i];
    }
  }
  return max;
};

console.log(findMax([3, 8, 10, 5]));`

const s6 = `const calculateAverage = arr => {
  let sum = 0;
  for (let num of arr) {
  sum += num;
  }
  return sum / arr.length;
};

console.log(calculateAverage([10, 20, 30, 40, 50]));`

const s7 = `const countVowels = str => {
  const vowels = 'aeiouAEIOU';
  let count = 0;
  for (let char of str) {
    if (vowels.includes(char)) {
      count++;
    }
  }
  return count;
};

console.log(countVowels("Hello"));`

export const codeSnippets = [s1, s2, s3, s4, s5, s6, s7];
