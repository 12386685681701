import { useCallback, useEffect, useRef, useState } from "react";

export const Input = (props) => {

    const textAreaRef = useRef(null);

    const [text, setText] = useState('');

    const {success} = props;

    const handleCorrectText = useCallback(() => {
        textAreaRef.current.value = '';
        setText('');
        success();
    }, [success]);

    const handleCheated = (e) => {
        e.preventDefault();
        props.cheated();
    }

    useEffect(() => {
        if (props.compareString && text === props.compareString) {
            handleCorrectText();
        }

    }, [text, props.compareString, handleCorrectText]);

    const handleKeyDown = (e) => {
        if (e.key === 'Backspace') {
            props.onBackSpacePressed();
        } else if (e.key === 'Tab') {
            e.preventDefault();
            const value = textAreaRef.current.value;
            const selectionStart = textAreaRef.current.selectionStart;
            const selectionEnd = textAreaRef.current.selectionEnd;
            textAreaRef.current.value =
                value.substring(0, selectionStart) + '  ' + value.substring(selectionEnd);
            textAreaRef.current.selectionStart = selectionEnd + 2 - (selectionEnd - selectionStart);
            textAreaRef.current.selectionEnd = selectionEnd + 2 - (selectionEnd - selectionStart);
        } else {
            props.onKeyDown();
        }
    }

    return (
        <div style={{marginTop: '20px'}}>
            <textarea
                className="text-area input-text-area"
                autoFocus={true}
                ref={textAreaRef}
                type="text"
                onChange={event => setText(event.target.value)}
                onKeyDown={handleKeyDown}
                onPaste={handleCheated}
                onDrop={handleCheated}
                autoComplete="off"
            />

        </div>
    )
}