import { CodeSnippetsRetriever } from '../codeSnippets/codeSnippetsRetriever';
import { useIsCheated, useIsGameFinished, useIsGameNew, useIsGameRunning } from '../hooks/gameStateHooks';
import { CheatedView } from './cheatedView';
import { GameView } from './gameView';
import { MainView } from './mainView';
import { ResultView } from './resultView';

export const WrapperView = (props) => {

    const GAME_RUNTIME_SECONDS = 120;
    const MAX_TOP_USERS = 5;
    const STATS_FUNCTION_ENDPOINT = '/stats';
  
    const codeSnippetsRetriever = new CodeSnippetsRetriever();

    const isGameNew = useIsGameNew();
    const isGameRunning = useIsGameRunning();
    const isGameFinished = useIsGameFinished();
    const isCheated = useIsCheated();

    return (
        <div>
            {isGameNew && (
                <MainView statsUrl={STATS_FUNCTION_ENDPOINT}/>
            )}
            {isGameRunning && (
                <GameView codeSnippetsRetriever={codeSnippetsRetriever} gameRuntimeSeconds={GAME_RUNTIME_SECONDS} />
            )}
            {isGameFinished && (
                <ResultView gameRuntimeSeconds={GAME_RUNTIME_SECONDS} statsUrl={STATS_FUNCTION_ENDPOINT} maxTopUsers={MAX_TOP_USERS} />
            )}
            {isCheated && (
                <CheatedView statsUrl={STATS_FUNCTION_ENDPOINT} />
            )}
        </div >
    );
}
